import { render, staticRenderFns } from "./Pagination.vue?vue&type=template&id=fbe14466&"
import script from "./Pagination.vue?vue&type=script&lang=js&"
export * from "./Pagination.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\xampp\\htdocs\\e2e_invoicing\\invoicebull\\invoicingapp_1\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('fbe14466')) {
      api.createRecord('fbe14466', component.options)
    } else {
      api.reload('fbe14466', component.options)
    }
    module.hot.accept("./Pagination.vue?vue&type=template&id=fbe14466&", function () {
      api.rerender('fbe14466', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/components/base/base-table/components/Pagination.vue"
export default component.exports