<template>
  <div id="home">
    

    <nav class="navbar navbar-expand-lg navbar-light bg-white px-8 fixed-top">
      <a class="navbar-brand" href="#">
        <img
          src="/assets/img/invoicebull.svg"
          alt="Invoice bull Logo"
          style="max-width: 160px; height: auto;"
        >
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarTogglerDemo02"
        aria-controls="navbarTogglerDemo02"
        aria-expanded="false"
        aria-label="Toggle navigation"
        @click="toggleMobileMenu"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item " :class="{ active: activeSection === 'home' }">
            <a class="nav-link" @click="scrollToSection('home')">Home</a>
          </li>
          <li class="nav-item" :class="{ active: activeSection === 'aboutUs' }">
            <a class="nav-link" @click="scrollToSection('aboutUs')">About</a>
          </li>
          <li class="nav-item" :class="{ active: activeSection === 'features' }">
            <a class="nav-link" @click="scrollToSection('features')">Features</a>
          </li>
          <li class="nav-item" :class="{ active: activeSection === 'testimonials' }">
            <a class="nav-link" @click="scrollToSection('testimonials')">Testimonials</a>
          </li>
          <li class="nav-item" :class="{ active: activeSection === 'blog' }">
            <a class="nav-link" @click="scrollToSection('blog')">Blog</a>
          </li>
          <li class="nav-item">
            <router-link to="/auth/login" class="nav-link">
              Login
            </router-link>
          </li>
        </ul>
      </div>
    </nav>
    <div class="bg-white header-top-style">
      <div class="jumbotron header-style">
        <div class="container">
          <h1>Simplifying Your Financial World</h1>
          <p>Create Invoices and Estimates</p>
          <a class="btn btn-primary learnmore-btn-light" @click="scrollToSection('aboutUs')">Learn More</a>
        </div>
      </div>
    </div>

    <div class="about-section" id="aboutUs">
      <div class="container">
        <h2>About invoice Bull</h2>
        <div class="divider-padding"></div>
        <div class="about-content">
          
          <div class="col justify-content">
            <ul>
              <li><strong>Invoice Bull</strong> A platform that plays a pivotal role in creating Invoices and estimates and Billings</li>
              <li>Invoice Bull conveys vital information regarding a specific sale or supply, encapsulating details such as the buyer's information, quantity of goods or services provided, their respective values, applicable taxes, and the agreed-upon payment terms.</li>
              <li>In essence, Invoice Bull serves as a formal proof of the agreement and transaction, ensuring transparency and accountability.</li>
            </ul>
            
            
          </div>
          <div class="col justify-content">
            <ul>
              <li><strong>Efficiency:</strong> Streamline financial processes, saving time and reducing errors.</li>
              <li><strong>Customization:</strong> Create branded, professional documents with customizable templates.</li>
              <li><strong>Security:</strong> Protect your data with state-of-the-art encryption and security measures.</li>
              <li><strong>Support:</strong> Access excellent customer support for assistance and questions.</li>
            </ul>
          </div>
          
          <!-- <ul class="checklist">
            <li>Ullamco laboris nisi ut aliquip ex ea commodo consequat</li>
            <li>Duis aute irure dolor in reprehenderit in voluptate velit</li>
            <li>Ullamco laboris nisi ut aliquip ex ea commodo consequat</li>
          </ul> -->
        </div>
        <a href="#" class="button learnmore-btn learnbtn-hover-lt" @click="scrollToSection('features')">Learn More</a>
      </div>
    </div>

    <div class="features" id="features">
      <div class="container">
        <h2 class="text-center">Features</h2>
        <div class="divider-padding"></div>
        <div class="row">
          <div class="col-md-4 feature">
             <img src="https://cdn.dribbble.com/users/1991539/screenshots/9106913/media/1700d8dbfb241934105c2d44e567143e.png?resize=400x0" alt="Feature 1">
             <div class="features-subtitle">
              <h3>Feature 1</h3>
              <p>Description of feature 1.</p>
            </div>
          </div>
          <div class="col-md-4 feature">
            <img src="https://assets.materialup.com/uploads/f3b97b6a-d2cb-4268-9a46-b80bce193bbb/preview.png" alt="Feature 2">
            <div class="features-subtitle">
            <h3>Feature 2</h3>
            <p>Description of feature 2.</p>
            </div>
          </div>
          <div class="col-md-4 feature">
            <img src="https://cdn.dribbble.com/users/1991539/screenshots/9106913/media/1700d8dbfb241934105c2d44e567143e.png?resize=400x0" alt="Feature 1">
            <div class="features-subtitle">
            <h3>Feature 3</h3>
            <p>Description of feature 3.</p>
            </div>
          </div>
        </div>
      </div>
    </div>

<div class="testimonials" id="testimonials">
  <h2>Testimonial</h2>
  <div class="divider-padding"></div>
     <!-- Carousel wrapper -->
<div id="carouselMultiItemExample" class="carousel slide carousel-dark text-center" data-mdb-ride="carousel">
 
  <!-- Inner -->
  <div class="carousel-inner py-4">
    <!-- Single item -->
    <div class="carousel-item active">
      <div class="container">
        <div class="row">
          <div class="col-lg-4">
            <img class="rounded-circle shadow-1-strong mb-4"
              src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(1).webp" alt="avatar"
              style="width: 150px;" />
            <h5 class="mb-3">Anna Deynah</h5>
           
            <p class="text-muted">
              <i class="fas fa-quote-left pe-2"></i>
              user-friendly platform allows me to create professional invoices and estimates in minutes, saving me time and improving my financial efficiency.
            </p>
            <ul class="list-unstyled d-flex justify-content-center text-warning mb-0">
              <li><i class="fas fa-star fa-sm"></i></li>
              <li><i class="fas fa-star fa-sm"></i></li>
              <li><i class="fas fa-star fa-sm"></i></li>
              <li><i class="fas fa-star fa-sm"></i></li>
              <li><i class="fas fa-star fa-sm"></i></li>
            </ul>
          </div>

          <div class="col-lg-4 d-none d-lg-block">
            <img class="rounded-circle shadow-1-strong mb-4"
              src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(32).webp" alt="avatar"
              style="width: 150px;" />
            <h5 class="mb-3">John Doe</h5>
            
            <p class="text-muted">
              <i class="fas fa-quote-left pe-2"></i>
              user-friendly platform allows me to create professional invoices and estimates in minutes, saving me time and improving my financial efficiency.
            </p>
            <ul class="list-unstyled d-flex justify-content-center text-warning mb-0">
              <li><i class="fas fa-star fa-sm"></i></li>
              <li><i class="fas fa-star fa-sm"></i></li>
              <li><i class="fas fa-star fa-sm"></i></li>
              <li><i class="fas fa-star fa-sm"></i></li>
              <li>
                <i class="fas fa-star-half-alt fa-sm"></i>
              </li>
            </ul>
          </div>

          <div class="col-lg-4 d-none d-lg-block">
            <img class="rounded-circle shadow-1-strong mb-4"
              src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(10).webp" alt="avatar"
              style="width: 150px;" />
            <h5 class="mb-3">Maria Kate</h5>
           
            <p class="text-muted">
              <i class="fas fa-quote-left pe-2"></i>
              user-friendly platform allows me to create professional invoices and estimates in minutes, saving me time and improving my financial efficiency.
            </p>
            <ul class="list-unstyled d-flex justify-content-center text-warning mb-0">
              <li><i class="fas fa-star fa-sm"></i></li>
              <li><i class="fas fa-star fa-sm"></i></li>
              <li><i class="fas fa-star fa-sm"></i></li>
              <li><i class="fas fa-star fa-sm"></i></li>
              <li><i class="far fa-star fa-sm"></i></li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- Single item -->
   

  
  </div>
  <!-- Inner -->
</div>
<!-- Carousel wrapper -->

</div>

<div class="features blog-section" id="blog">
      <div class="container">
        <h2 class="text-center primary-color-text">Our latest Blog</h2>
        <div class="divider-padding"></div>
        <div class="row">
          <div class="col-md-4 feature">
            <div class="blog-container"><img src="https://cdn.dribbble.com/users/1991539/screenshots/9106913/media/1700d8dbfb241934105c2d44e567143e.png?resize=400x0" class="round-image" alt="Feature 1"></div>
             <div class="features-subtitle">
              <h3>Feature 1</h3>
              <p>Description of feature 1.</p>
            </div>
          </div>
          <div class="col-md-4 feature">
            <div class="blog-container"><img src="https://assets.materialup.com/uploads/f3b97b6a-d2cb-4268-9a46-b80bce193bbb/preview.png" class="round-image" alt="Feature 2"></div>
            <div class="features-subtitle">
            <h3>Feature 2</h3>
            <p>Description of feature 2.</p>
            </div>
          </div>
          <div class="col-md-4 feature">
            <div class="blog-container"><img src="https://cdn.dribbble.com/users/1991539/screenshots/9106913/media/1700d8dbfb241934105c2d44e567143e.png?resize=400x0" class="round-image" alt="Feature 1"></div>
            <div class="features-subtitle">
            <h3>Feature 3</h3>
            <p>Description of feature 3.</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container text-center mt-5">
    <h2>Partner with us</h2>
    <div class="row justify-content-center mt-4">
        <div class="col-md-4 mb-3">
            <h5>Mailing Address</h5>
            <p>123 Anywhere St., Any City, State, Country 12345</p>
        </div>
        <div class="col-md-4 mb-3">
            <h5>Email Address</h5>
            <p>hello@reallygreatsite.com</p>
        </div>
        <div class="col-md-4 mb-3">
            <h5>Phone Number</h5>
            <p>(123) 456 7890</p>
        </div>
    </div>
</div>   
   

    <footer class="site-footer footer-style-main">
      <input type="hidden" id="authtoken" />
      <div class="text-center">
        {{ $t('general.powered_by') }}
        <a href="www.invoicebull.com" target="_blank">Invoice Bull</a>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeSection: 'home', // Initialize with the default section (e.g., 'home'),
      mobileMenuOpen: false
    };
  },
  watch: {
    $route: 'onRouteChange'
  },
  methods: {
    toggleMobileMenu() {
      this.mobileMenuOpen = !this.mobileMenuOpen;
    },
    scrollToSection(sectionId) {
      const section = document.querySelector(`#${sectionId}`);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
        this.activeSection = sectionId;
      }
    },
    onRouteChange() {
      this.activeSection = this.$route.name; // Set activeSection based on route name
    }
  }
};
</script>
