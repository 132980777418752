var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "home" } }, [
    _c(
      "nav",
      {
        staticClass:
          "navbar navbar-expand-lg navbar-light bg-white px-8 fixed-top",
      },
      [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "navbar-toggler",
            attrs: {
              type: "button",
              "data-toggle": "collapse",
              "data-target": "#navbarTogglerDemo02",
              "aria-controls": "navbarTogglerDemo02",
              "aria-expanded": "false",
              "aria-label": "Toggle navigation",
            },
            on: { click: _vm.toggleMobileMenu },
          },
          [_c("span", { staticClass: "navbar-toggler-icon" })]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "collapse navbar-collapse",
            attrs: { id: "navbarTogglerDemo02" },
          },
          [
            _c("ul", { staticClass: "navbar-nav ml-auto" }, [
              _c(
                "li",
                {
                  staticClass: "nav-item ",
                  class: { active: _vm.activeSection === "home" },
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link",
                      on: {
                        click: function ($event) {
                          return _vm.scrollToSection("home")
                        },
                      },
                    },
                    [_vm._v("Home")]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  staticClass: "nav-item",
                  class: { active: _vm.activeSection === "aboutUs" },
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link",
                      on: {
                        click: function ($event) {
                          return _vm.scrollToSection("aboutUs")
                        },
                      },
                    },
                    [_vm._v("About")]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  staticClass: "nav-item",
                  class: { active: _vm.activeSection === "features" },
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link",
                      on: {
                        click: function ($event) {
                          return _vm.scrollToSection("features")
                        },
                      },
                    },
                    [_vm._v("Features")]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  staticClass: "nav-item",
                  class: { active: _vm.activeSection === "testimonials" },
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link",
                      on: {
                        click: function ($event) {
                          return _vm.scrollToSection("testimonials")
                        },
                      },
                    },
                    [_vm._v("Testimonials")]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  staticClass: "nav-item",
                  class: { active: _vm.activeSection === "blog" },
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link",
                      on: {
                        click: function ($event) {
                          return _vm.scrollToSection("blog")
                        },
                      },
                    },
                    [_vm._v("Blog")]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c(
                    "router-link",
                    { staticClass: "nav-link", attrs: { to: "/auth/login" } },
                    [_vm._v("\n              Login\n            ")]
                  ),
                ],
                1
              ),
            ]),
          ]
        ),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "bg-white header-top-style" }, [
      _c("div", { staticClass: "jumbotron header-style" }, [
        _c("div", { staticClass: "container" }, [
          _c("h1", [_vm._v("Simplifying Your Financial World")]),
          _vm._v(" "),
          _c("p", [_vm._v("Create Invoices and Estimates")]),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "btn btn-primary learnmore-btn-light",
              on: {
                click: function ($event) {
                  return _vm.scrollToSection("aboutUs")
                },
              },
            },
            [_vm._v("Learn More")]
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "about-section", attrs: { id: "aboutUs" } }, [
      _c("div", { staticClass: "container" }, [
        _c("h2", [_vm._v("About invoice Bull")]),
        _vm._v(" "),
        _c("div", { staticClass: "divider-padding" }),
        _vm._v(" "),
        _vm._m(1),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "button learnmore-btn learnbtn-hover-lt",
            attrs: { href: "#" },
            on: {
              click: function ($event) {
                return _vm.scrollToSection("features")
              },
            },
          },
          [_vm._v("Learn More")]
        ),
      ]),
    ]),
    _vm._v(" "),
    _vm._m(2),
    _vm._v(" "),
    _vm._m(3),
    _vm._v(" "),
    _vm._m(4),
    _vm._v(" "),
    _vm._m(5),
    _vm._v(" "),
    _c("footer", { staticClass: "site-footer footer-style-main" }, [
      _c("input", { attrs: { type: "hidden", id: "authtoken" } }),
      _vm._v(" "),
      _c("div", { staticClass: "text-center" }, [
        _vm._v(
          "\n        " + _vm._s(_vm.$t("general.powered_by")) + "\n        "
        ),
        _c("a", { attrs: { href: "www.invoicebull.com", target: "_blank" } }, [
          _vm._v("Invoice Bull"),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { staticClass: "navbar-brand", attrs: { href: "#" } }, [
      _c("img", {
        staticStyle: { "max-width": "160px", height: "auto" },
        attrs: { src: "/assets/img/invoicebull.svg", alt: "Invoice bull Logo" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "about-content" }, [
      _c("div", { staticClass: "col justify-content" }, [
        _c("ul", [
          _c("li", [
            _c("strong", [_vm._v("Invoice Bull")]),
            _vm._v(
              " A platform that plays a pivotal role in creating Invoices and estimates and Billings"
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _vm._v(
              "Invoice Bull conveys vital information regarding a specific sale or supply, encapsulating details such as the buyer's information, quantity of goods or services provided, their respective values, applicable taxes, and the agreed-upon payment terms."
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _vm._v(
              "In essence, Invoice Bull serves as a formal proof of the agreement and transaction, ensuring transparency and accountability."
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col justify-content" }, [
        _c("ul", [
          _c("li", [
            _c("strong", [_vm._v("Efficiency:")]),
            _vm._v(
              " Streamline financial processes, saving time and reducing errors."
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _c("strong", [_vm._v("Customization:")]),
            _vm._v(
              " Create branded, professional documents with customizable templates."
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _c("strong", [_vm._v("Security:")]),
            _vm._v(
              " Protect your data with state-of-the-art encryption and security measures."
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _c("strong", [_vm._v("Support:")]),
            _vm._v(
              " Access excellent customer support for assistance and questions."
            ),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "features", attrs: { id: "features" } }, [
      _c("div", { staticClass: "container" }, [
        _c("h2", { staticClass: "text-center" }, [_vm._v("Features")]),
        _vm._v(" "),
        _c("div", { staticClass: "divider-padding" }),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-4 feature" }, [
            _c("img", {
              attrs: {
                src: "https://cdn.dribbble.com/users/1991539/screenshots/9106913/media/1700d8dbfb241934105c2d44e567143e.png?resize=400x0",
                alt: "Feature 1",
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "features-subtitle" }, [
              _c("h3", [_vm._v("Feature 1")]),
              _vm._v(" "),
              _c("p", [_vm._v("Description of feature 1.")]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-4 feature" }, [
            _c("img", {
              attrs: {
                src: "https://assets.materialup.com/uploads/f3b97b6a-d2cb-4268-9a46-b80bce193bbb/preview.png",
                alt: "Feature 2",
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "features-subtitle" }, [
              _c("h3", [_vm._v("Feature 2")]),
              _vm._v(" "),
              _c("p", [_vm._v("Description of feature 2.")]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-4 feature" }, [
            _c("img", {
              attrs: {
                src: "https://cdn.dribbble.com/users/1991539/screenshots/9106913/media/1700d8dbfb241934105c2d44e567143e.png?resize=400x0",
                alt: "Feature 1",
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "features-subtitle" }, [
              _c("h3", [_vm._v("Feature 3")]),
              _vm._v(" "),
              _c("p", [_vm._v("Description of feature 3.")]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "testimonials", attrs: { id: "testimonials" } },
      [
        _c("h2", [_vm._v("Testimonial")]),
        _vm._v(" "),
        _c("div", { staticClass: "divider-padding" }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "carousel slide carousel-dark text-center",
            attrs: {
              id: "carouselMultiItemExample",
              "data-mdb-ride": "carousel",
            },
          },
          [
            _c("div", { staticClass: "carousel-inner py-4" }, [
              _c("div", { staticClass: "carousel-item active" }, [
                _c("div", { staticClass: "container" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-lg-4" }, [
                      _c("img", {
                        staticClass: "rounded-circle shadow-1-strong mb-4",
                        staticStyle: { width: "150px" },
                        attrs: {
                          src: "https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(1).webp",
                          alt: "avatar",
                        },
                      }),
                      _vm._v(" "),
                      _c("h5", { staticClass: "mb-3" }, [
                        _vm._v("Anna Deynah"),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "text-muted" }, [
                        _c("i", { staticClass: "fas fa-quote-left pe-2" }),
                        _vm._v(
                          "\n              user-friendly platform allows me to create professional invoices and estimates in minutes, saving me time and improving my financial efficiency.\n            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "ul",
                        {
                          staticClass:
                            "list-unstyled d-flex justify-content-center text-warning mb-0",
                        },
                        [
                          _c("li", [
                            _c("i", { staticClass: "fas fa-star fa-sm" }),
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("i", { staticClass: "fas fa-star fa-sm" }),
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("i", { staticClass: "fas fa-star fa-sm" }),
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("i", { staticClass: "fas fa-star fa-sm" }),
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("i", { staticClass: "fas fa-star fa-sm" }),
                          ]),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-lg-4 d-none d-lg-block" }, [
                      _c("img", {
                        staticClass: "rounded-circle shadow-1-strong mb-4",
                        staticStyle: { width: "150px" },
                        attrs: {
                          src: "https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(32).webp",
                          alt: "avatar",
                        },
                      }),
                      _vm._v(" "),
                      _c("h5", { staticClass: "mb-3" }, [_vm._v("John Doe")]),
                      _vm._v(" "),
                      _c("p", { staticClass: "text-muted" }, [
                        _c("i", { staticClass: "fas fa-quote-left pe-2" }),
                        _vm._v(
                          "\n              user-friendly platform allows me to create professional invoices and estimates in minutes, saving me time and improving my financial efficiency.\n            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "ul",
                        {
                          staticClass:
                            "list-unstyled d-flex justify-content-center text-warning mb-0",
                        },
                        [
                          _c("li", [
                            _c("i", { staticClass: "fas fa-star fa-sm" }),
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("i", { staticClass: "fas fa-star fa-sm" }),
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("i", { staticClass: "fas fa-star fa-sm" }),
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("i", { staticClass: "fas fa-star fa-sm" }),
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("i", {
                              staticClass: "fas fa-star-half-alt fa-sm",
                            }),
                          ]),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-lg-4 d-none d-lg-block" }, [
                      _c("img", {
                        staticClass: "rounded-circle shadow-1-strong mb-4",
                        staticStyle: { width: "150px" },
                        attrs: {
                          src: "https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(10).webp",
                          alt: "avatar",
                        },
                      }),
                      _vm._v(" "),
                      _c("h5", { staticClass: "mb-3" }, [_vm._v("Maria Kate")]),
                      _vm._v(" "),
                      _c("p", { staticClass: "text-muted" }, [
                        _c("i", { staticClass: "fas fa-quote-left pe-2" }),
                        _vm._v(
                          "\n              user-friendly platform allows me to create professional invoices and estimates in minutes, saving me time and improving my financial efficiency.\n            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "ul",
                        {
                          staticClass:
                            "list-unstyled d-flex justify-content-center text-warning mb-0",
                        },
                        [
                          _c("li", [
                            _c("i", { staticClass: "fas fa-star fa-sm" }),
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("i", { staticClass: "fas fa-star fa-sm" }),
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("i", { staticClass: "fas fa-star fa-sm" }),
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("i", { staticClass: "fas fa-star fa-sm" }),
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("i", { staticClass: "far fa-star fa-sm" }),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]
        ),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "features blog-section", attrs: { id: "blog" } },
      [
        _c("div", { staticClass: "container" }, [
          _c("h2", { staticClass: "text-center primary-color-text" }, [
            _vm._v("Our latest Blog"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "divider-padding" }),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-4 feature" }, [
              _c("div", { staticClass: "blog-container" }, [
                _c("img", {
                  staticClass: "round-image",
                  attrs: {
                    src: "https://cdn.dribbble.com/users/1991539/screenshots/9106913/media/1700d8dbfb241934105c2d44e567143e.png?resize=400x0",
                    alt: "Feature 1",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "features-subtitle" }, [
                _c("h3", [_vm._v("Feature 1")]),
                _vm._v(" "),
                _c("p", [_vm._v("Description of feature 1.")]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-4 feature" }, [
              _c("div", { staticClass: "blog-container" }, [
                _c("img", {
                  staticClass: "round-image",
                  attrs: {
                    src: "https://assets.materialup.com/uploads/f3b97b6a-d2cb-4268-9a46-b80bce193bbb/preview.png",
                    alt: "Feature 2",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "features-subtitle" }, [
                _c("h3", [_vm._v("Feature 2")]),
                _vm._v(" "),
                _c("p", [_vm._v("Description of feature 2.")]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-4 feature" }, [
              _c("div", { staticClass: "blog-container" }, [
                _c("img", {
                  staticClass: "round-image",
                  attrs: {
                    src: "https://cdn.dribbble.com/users/1991539/screenshots/9106913/media/1700d8dbfb241934105c2d44e567143e.png?resize=400x0",
                    alt: "Feature 1",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "features-subtitle" }, [
                _c("h3", [_vm._v("Feature 3")]),
                _vm._v(" "),
                _c("p", [_vm._v("Description of feature 3.")]),
              ]),
            ]),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container text-center mt-5" }, [
      _c("h2", [_vm._v("Partner with us")]),
      _vm._v(" "),
      _c("div", { staticClass: "row justify-content-center mt-4" }, [
        _c("div", { staticClass: "col-md-4 mb-3" }, [
          _c("h5", [_vm._v("Mailing Address")]),
          _vm._v(" "),
          _c("p", [_vm._v("123 Anywhere St., Any City, State, Country 12345")]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-4 mb-3" }, [
          _c("h5", [_vm._v("Email Address")]),
          _vm._v(" "),
          _c("p", [_vm._v("hello@reallygreatsite.com")]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-4 mb-3" }, [
          _c("h5", [_vm._v("Phone Number")]),
          _vm._v(" "),
          _c("p", [_vm._v("(123) 456 7890")]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }