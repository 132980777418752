var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "invoice-index-page invoices ",
      class: { "main-content": true, "main-content-with-iframe": !true },
    },
    [
      _c("div", { staticClass: "page-header" }, [
        _c("h3", { staticClass: "page-title" }, [
          _vm._v(" " + _vm._s(_vm.$t("invoices.title"))),
        ]),
        _vm._v(" "),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c(
                "router-link",
                {
                  attrs: { slot: "item-title", to: "dashboard" },
                  slot: "item-title",
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("general.home")) +
                      "\n        "
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c(
                "router-link",
                { attrs: { slot: "item-title", to: "#" }, slot: "item-title" },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$tc("invoices.invoice", 2)) +
                      "\n        "
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "page-actions row" },
          [
            _c(
              "div",
              { staticClass: "col-xs-2 mr-4" },
              [
                _c(
                  "base-button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.totalInvoices || _vm.filtersApplied,
                        expression: "totalInvoices || filtersApplied",
                      },
                    ],
                    attrs: {
                      outline: true,
                      icon: _vm.filterIcon,
                      size: "large",
                      color: "theme",
                      "right-icon": "",
                    },
                    on: { click: _vm.toggleFilter },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("general.filter")) +
                        "\n        "
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass: "col-xs-2",
                attrs: { slot: "item-title", to: "/admin/invoices/create" },
                slot: "item-title",
              },
              [
                _c(
                  "base-button",
                  { attrs: { size: "large", icon: "plus", color: "theme" } },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("invoices.new_invoice")) +
                        "\n        "
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showFilters,
                expression: "showFilters",
              },
            ],
            staticClass: "filter-section",
          },
          [
            _c("div", { staticClass: "filter-container" }, [
              _c(
                "div",
                { staticClass: "filter-customer" },
                [
                  _c("label", [
                    _vm._v(_vm._s(_vm.$tc("customers.customer", 1)) + " "),
                  ]),
                  _vm._v(" "),
                  _c("base-customer-select", {
                    ref: "customerSelect",
                    on: {
                      select: _vm.onSelectCustomer,
                      deselect: _vm.clearCustomerSearch,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "filter-status" },
                [
                  _c("label", [_vm._v(_vm._s(_vm.$t("invoices.status")))]),
                  _vm._v(" "),
                  _c("base-select", {
                    attrs: {
                      options: _vm.status,
                      "group-select": false,
                      searchable: true,
                      "show-labels": false,
                      placeholder: _vm.$t("general.select_a_status"),
                      "group-values": "options",
                      "group-label": "label",
                      "track-by": "name",
                      label: "name",
                    },
                    on: {
                      remove: function ($event) {
                        return _vm.clearStatusSearch()
                      },
                    },
                    model: {
                      value: _vm.filters.status,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "status", $$v)
                      },
                      expression: "filters.status",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "filter-date" }, [
                _c(
                  "div",
                  { staticClass: "from pr-3" },
                  [
                    _c("label", [_vm._v(_vm._s(_vm.$t("general.from")))]),
                    _vm._v(" "),
                    _c("base-date-picker", {
                      attrs: {
                        "calendar-button": true,
                        "calendar-button-icon": "calendar",
                      },
                      model: {
                        value: _vm.filters.from_date,
                        callback: function ($$v) {
                          _vm.$set(_vm.filters, "from_date", $$v)
                        },
                        expression: "filters.from_date",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "dashed" }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "to pl-3" },
                  [
                    _c("label", [_vm._v(_vm._s(_vm.$t("general.to")))]),
                    _vm._v(" "),
                    _c("base-date-picker", {
                      attrs: {
                        "calendar-button": true,
                        "calendar-button-icon": "calendar",
                      },
                      model: {
                        value: _vm.filters.to_date,
                        callback: function ($$v) {
                          _vm.$set(_vm.filters, "to_date", $$v)
                        },
                        expression: "filters.to_date",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "filter-invoice" },
                [
                  _c("label", [
                    _vm._v(_vm._s(_vm.$t("invoices.invoice_number"))),
                  ]),
                  _vm._v(" "),
                  _c("base-input", {
                    attrs: { icon: "hashtag" },
                    model: {
                      value: _vm.filters.invoice_number,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "invoice_number", $$v)
                      },
                      expression: "filters.invoice_number",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "label",
              { staticClass: "clear-filter", on: { click: _vm.clearFilter } },
              [_vm._v(_vm._s(_vm.$t("general.clear_all")))]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showEmptyScreen,
              expression: "showEmptyScreen",
            },
          ],
          staticClass: "col-xs-1 no-data-info",
          attrs: { align: "center" },
        },
        [
          _c("moon-walker-icon", { staticClass: "mt-5 mb-4" }),
          _vm._v(" "),
          _c("div", { staticClass: "row", attrs: { align: "center" } }, [
            _c("label", { staticClass: "col title" }, [
              _vm._v(_vm._s(_vm.$t("invoices.no_invoices"))),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "label",
              {
                staticClass: "description col mt-1",
                attrs: { align: "center" },
              },
              [_vm._v(_vm._s(_vm.$t("invoices.list_of_invoices")))]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "btn-container" },
            [
              _c(
                "base-button",
                {
                  staticClass: "mt-3",
                  attrs: { outline: true, color: "theme", size: "large" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.push("invoices/create")
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("invoices.new_invoice")) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showEmptyScreen,
              expression: "!showEmptyScreen",
            },
          ],
          staticClass: "table-container",
        },
        [
          _c(
            "div",
            { staticClass: "table-actions mt-5" },
            [
              _c("p", { staticClass: "table-stats" }, [
                _vm._v(_vm._s(_vm.$t("general.showing")) + ": "),
                _c("b", [_vm._v(_vm._s(_vm.invoices.length))]),
                _vm._v(" " + _vm._s(_vm.$t("general.of")) + " "),
                _c("b", [_vm._v(_vm._s(_vm.totalInvoices))]),
              ]),
              _vm._v(" "),
              _c("ul", { staticClass: "tabs" }, [
                _c(
                  "li",
                  {
                    staticClass: "tab",
                    on: {
                      click: function ($event) {
                        return _vm.getStatus("UNPAID")
                      },
                    },
                  },
                  [
                    _c(
                      "a",
                      {
                        class: [
                          "tab-link",
                          { "a-active": _vm.filters.status.value === "UNPAID" },
                        ],
                        attrs: { href: "#" },
                      },
                      [_vm._v(_vm._s(_vm.$t("general.due")))]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    staticClass: "tab",
                    on: {
                      click: function ($event) {
                        return _vm.getStatus("DRAFT")
                      },
                    },
                  },
                  [
                    _c(
                      "a",
                      {
                        class: [
                          "tab-link",
                          { "a-active": _vm.filters.status.value === "DRAFT" },
                        ],
                        attrs: { href: "#" },
                      },
                      [_vm._v(_vm._s(_vm.$t("general.draft")))]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    staticClass: "tab",
                    on: {
                      click: function ($event) {
                        return _vm.getStatus("")
                      },
                    },
                  },
                  [
                    _c(
                      "a",
                      {
                        class: [
                          "tab-link",
                          {
                            "a-active":
                              _vm.filters.status.value === "" ||
                              _vm.filters.status.value === null ||
                              (_vm.filters.status.value !== "DRAFT" &&
                                _vm.filters.status.value !== "UNPAID"),
                          },
                        ],
                        attrs: { href: "#" },
                      },
                      [_vm._v(_vm._s(_vm.$t("general.all")))]
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "transition",
                { attrs: { name: "fade" } },
                [
                  _vm.selectedInvoices.length
                    ? _c(
                        "v-dropdown",
                        { attrs: { "show-arrow": false } },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "table-actions-button dropdown-toggle",
                              attrs: { slot: "activator", href: "#" },
                              slot: "activator",
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("general.actions")) +
                                  "\n          "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("v-dropdown-item", [
                            _c(
                              "div",
                              {
                                staticClass: "dropdown-item",
                                on: { click: _vm.removeMultipleInvoices },
                              },
                              [
                                _c("font-awesome-icon", {
                                  staticClass: "dropdown-item-icon",
                                  attrs: { icon: ["fas", "trash"] },
                                }),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("general.delete")) +
                                    "\n            "
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "custom-control custom-checkbox" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.selectAllFieldStatus,
                  expression: "selectAllFieldStatus",
                },
              ],
              staticClass: "custom-control-input",
              attrs: { id: "select-all", type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.selectAllFieldStatus)
                  ? _vm._i(_vm.selectAllFieldStatus, null) > -1
                  : _vm.selectAllFieldStatus,
              },
              on: {
                change: [
                  function ($event) {
                    var $$a = _vm.selectAllFieldStatus,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          (_vm.selectAllFieldStatus = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.selectAllFieldStatus = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.selectAllFieldStatus = $$c
                    }
                  },
                  _vm.selectAllInvoices,
                ],
              },
            }),
            _vm._v(" "),
            _c(
              "label",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isRequestOngoing,
                    expression: "!isRequestOngoing",
                  },
                ],
                staticClass: "custom-control-label selectall",
                attrs: { for: "select-all" },
              },
              [
                _c("span", { staticClass: "select-all-label" }, [
                  _vm._v(_vm._s(_vm.$t("general.select_all")) + " "),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "table-component",
            {
              ref: "table",
              attrs: {
                "show-filter": false,
                data: _vm.fetchData,
                "table-class": "table",
              },
            },
            [
              _c("table-column", {
                attrs: {
                  sortable: false,
                  filterable: false,
                  "cell-class": "no-click",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c(
                          "div",
                          { staticClass: "custom-control custom-checkbox" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.selectField,
                                  expression: "selectField",
                                },
                              ],
                              staticClass: "custom-control-input",
                              attrs: { id: row.id, type: "checkbox" },
                              domProps: {
                                value: row.id,
                                checked: Array.isArray(_vm.selectField)
                                  ? _vm._i(_vm.selectField, row.id) > -1
                                  : _vm.selectField,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.selectField,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = row.id,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.selectField = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.selectField = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.selectField = $$c
                                  }
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("label", {
                              staticClass: "custom-control-label",
                              attrs: { for: row.id },
                            }),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("table-column", {
                attrs: {
                  label: _vm.$t("invoices.date"),
                  "sort-as": "invoice_date",
                  show: "formattedInvoiceDate",
                },
              }),
              _vm._v(" "),
              _c("table-column", {
                attrs: {
                  label: _vm.$t("invoices.customer"),
                  width: "20%",
                  show: "name",
                },
              }),
              _vm._v(" "),
              _c("table-column", {
                attrs: {
                  label: _vm.$t("invoices.status"),
                  "sort-as": "status",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(" " + _vm._s(_vm.$t("invoices.status"))),
                        ]),
                        _vm._v(" "),
                        _c(
                          "span",
                          { class: "inv-status-" + row.status.toLowerCase() },
                          [
                            _vm._v(
                              _vm._s(
                                row.status != "PARTIALLY_PAID"
                                  ? row.status
                                  : row.status.replace("_", " ")
                              )
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("table-column", {
                attrs: {
                  label: _vm.$t("invoices.number"),
                  show: "invoice_number",
                },
              }),
              _vm._v(" "),
              _c("table-column", {
                attrs: {
                  label: _vm.$t("invoices.amount_due"),
                  "sort-as": "due_amount",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("invoices.amount_due"))),
                        ]),
                        _vm._v(" "),
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$utils.formatMoney(
                                row.due_amount,
                                row.user.currency
                              )
                            ),
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("table-column", {
                attrs: {
                  sortable: false,
                  filterable: false,
                  "cell-class": "action-dropdown no-click",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [_vm._v(_vm._s(_vm.$t("invoices.action")))]),
                        _vm._v(" "),
                        _c(
                          "v-dropdown",
                          [
                            _c(
                              "a",
                              {
                                attrs: { slot: "activator", href: "#" },
                                slot: "activator",
                              },
                              [_c("dot-icon")],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-dropdown-item",
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "dropdown-item",
                                    attrs: {
                                      to: {
                                        path: "invoices/" + row.id + "/edit",
                                      },
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      staticClass: "dropdown-item-icon",
                                      attrs: { icon: ["fas", "pencil-alt"] },
                                    }),
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$t("general.edit")) +
                                        "\n              "
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "dropdown-item",
                                    attrs: {
                                      to: {
                                        path: "invoices/" + row.id + "/view",
                                      },
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      staticClass: "dropdown-item-icon",
                                      attrs: { icon: "eye" },
                                    }),
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$t("invoices.view")) +
                                        "\n              "
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            row.status == "DRAFT"
                              ? _c("v-dropdown-item", [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "dropdown-item",
                                      attrs: { href: "#/" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.sendInvoice(row.id)
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        staticClass: "dropdown-item-icon",
                                        attrs: { icon: "paper-plane" },
                                      }),
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t("invoices.send_invoice")
                                          ) +
                                          "\n              "
                                      ),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            row.status == "DRAFT"
                              ? _c("v-dropdown-item", [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "dropdown-item",
                                      attrs: { href: "#/" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.markInvoiceAsSent(row.id)
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        staticClass: "dropdown-item-icon",
                                        attrs: { icon: "check-circle" },
                                      }),
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t("invoices.mark_as_sent")
                                          ) +
                                          "\n              "
                                      ),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            row.status === "SENT" ||
                            row.status === "VIEWED" ||
                            row.status === "OVERDUE"
                              ? _c(
                                  "v-dropdown-item",
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "dropdown-item",
                                        attrs: {
                                          to:
                                            "/admin/payments/" +
                                            row.id +
                                            "/create",
                                        },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          staticClass: "dropdown-item-icon",
                                          attrs: {
                                            icon: ["fas", "credit-card"],
                                          },
                                        }),
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.$t("payments.record_payment")
                                            ) +
                                            "\n              "
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("v-dropdown-item", [
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: { href: "#/" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onCloneInvoice(row.id)
                                    },
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    staticClass: "dropdown-item-icon",
                                    attrs: { icon: "copy" },
                                  }),
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("invoices.clone_invoice")) +
                                      "\n              "
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("v-dropdown-item", [
                              _c(
                                "div",
                                {
                                  staticClass: "dropdown-item",
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeInvoice(row.id)
                                    },
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    staticClass: "dropdown-item-icon",
                                    attrs: { icon: ["fas", "trash"] },
                                  }),
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("general.delete")) +
                                      "\n              "
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }